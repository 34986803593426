<template>
  <div class="main-layout">
    <MainHeader />
    <slot></slot>
    <ButtonBackToTop />
    <MainFooter />
  </div>
</template>

<script>
import ButtonBackToTop from '../common/ButtonBackToTop.vue';
import MainFooter from "./MainFooter.vue";
import MainHeader from "./MainHeader.vue";
export default {
  components: { MainHeader, MainFooter, ButtonBackToTop },
};
</script>
