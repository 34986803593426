<template>
  <div id="app">
    <MainLayout>
      <AboutUs />
      <SocialProof />
      <CallToAction />
      <FeatureSection />
      <DifferenceSection />
      <ClientReview />
    </MainLayout>
  </div>
</template>

<script>
import AboutUs from "./components/AboutUs.vue";
import CallToAction from "./components/CallToAction.vue";
import ClientReview from "./components/ClientReview.vue";
import DifferenceSection from "./components/DifferenceSection.vue";
import FeatureSection from "./components/FeatureSection.vue";
import MainLayout from "./components/Layout/MainLayout.vue";
import SocialProof from "./components/SocialProof.vue";

export default {
  name: "App",
  components: {
    MainLayout,
    AboutUs,
    SocialProof,
    CallToAction,
    FeatureSection,
    DifferenceSection,
    ClientReview,
  },
  created() {
    document.title = "Cydas People - Giải pháp quản trị nhân sự 4.0 số 1 Nhật Bản";
  },
};
</script>
