<template>
  <div class="layout">
    <div class="container">
      <div class="col-1">
        <h2 class="text-h1 title">{{ title }}</h2>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "CYDAS People được tin dùng bởi hơn 1,000 doanh nghiệp",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.layout {
  background: $grey;
  padding: 78px 0;

  @media screen and (max-width: calc($desktop-medium - 1px)) {
    padding: 40px 0;
  }

  @media screen and (max-width: calc($tablet - 1px)) {
    padding: 32px 0;
  }

  .container {
    padding-right: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;

    @media screen and (max-width: calc($desktop-medium - 1px)) {
      padding-right: 12px;
    }

    .col-1 {
      width: 400px;
      @media screen and (max-width: calc($desktop-medium - 1px)) {
        width: 100%;
      }
      .title {
        margin: 0;
        color: $primary;
        @media screen and (max-width: calc($desktop-medium - 1px)) {
          text-align: center;
        }
      }
    }

    .col-2 {
      width: calc(100% - 415px);
      background: white;
      border-radius: 16px;
      height: 573px;
      background-image: url(@/assets/images/industries.png);
      background-size: cover;
      background-position: center;

      @media screen and (max-width: calc($desktop-medium - 1px)) {
        width: 100%;
        height: unset;
        aspect-ratio: 9 / 5;
      }
    }
  }
}
</style>
