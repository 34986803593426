<template>
  <div class="layout">
    <div class="container">
      <div class="text-h1 title">{{ title }}</div>
      <div class="body">
        <div class="col-1">
          <div class="item" v-for="(data, idx) in listData" :key="idx">
            <img :src="data.icon" alt="" />
            <div class="content">
              <h5 class="text-h5 item-title">{{ data.title }}</h5>
              <p class="description text-body-extra-large">
                {{ data.description }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-2">
          <ImageFrame
            :styleFront="{ zIndex: -1, background: '#007BC3' }"
            width="600px"
          >
            <template v-slot:back>
              <img src="@/assets/images/difference.png" alt="" />
            </template>
          </ImageFrame>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageFrame from "./common/ImageFrame.vue";
export default {
  components: { ImageFrame },
  data() {
    return {
      title: "Điều tạo nên sự khác biệt của CYDAS People",
      listData: [
        {
          title: "Thay đổi linh hoạt theo nhu cầu",
          description:
            "Chúng tôi hiểu rằng mỗi doanh nghiệp là một tổ chúc riêng biệt với những định hướng và quy trình khác nhau. Với Cydas People, doanh nghiệp có thể dễ dàng tuỳ chỉnh các tính năng của hệ thống để đáp ứng nhu cầu của công ty.",
          icon: require("@/assets/icons/change-icon.svg"),
        },
        {
          title: "Có thể triển khai với quy mô lớn",
          description:
            "Chúng tôi đã triển khai thành công hệ thống CYDAS People cho nhiều doanh nghiệp lớn ở Nhật Bản với quy mô hàng nghìn nhân sự và chuyên môn đa ngành như ngân hàng Kiraboshi, hãng máy bay ANA, công ty Dược phẩm Sawai... ",
          icon: require("@/assets/icons/industries-icon.svg"),
        },
        {
          title: "Sẵn sàng sử dụng ngay",
          description:
            "Khi bắt đầu triển khai, chúng tôi cung cấp hệ thống cài đặt sẵn thông tin nhân viên và quy trình mà khách hàng đang sử dụng. Phòng nhân sự không cần thực hiện thêm bất kỳ cài đặt nào tại thời điểm triển khai.",
          icon: require("@/assets/icons/touch-icon.svg"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.layout {
  padding-bottom: 80px;
  @media screen and (max-width: calc($desktop-medium - 1px)) {
    padding-bottom: 40px;
  }
  @media screen and (max-width: calc($tablet - 1px)) {
    padding-bottom: 32px;
  }
  .title {
    color: $primary;
    text-align: center;
    margin-bottom: 80px;
    @media screen and (max-width: calc($desktop-medium - 1px)) {
      margin-bottom: 40px;
    }
    @media screen and (max-width: calc($tablet - 1px)) {
      margin-bottom: 24px;
    }
  }
  .body {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    @media screen and (max-width: calc($desktop-medium - 1px)) {
      flex-direction: column-reverse;
      gap: 32px;
    }
    @media screen and (max-width: calc($tablet - 1px)) {
      gap: 24px;
    }

    .col-1 {
      width: 480px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media screen and (max-width: calc($desktop-medium - 1px)) {
        width: 100%;
      }

      .item {
        display: flex;
        align-items: flex-start;
        padding: 16px;
        gap: 24px;
        border: 1px solid $primary;
        border-radius: 16px;
        transition: all 0.2s ease;

        @media screen and (max-width: calc($tablet - 1px)) {
          gap: 16px;
          padding: 12px;
        }

        &:hover {
          background: $primary;
          box-shadow: -1px 1px 8px #1d8af7;

          img {
            filter: brightness(0) invert(1);
          }
          .item-title,
          .description {
            color: white;
          }
        }

        img {
          width: 48px;
          aspect-ratio: 1 / 1;
          flex-shrink: 0;
          transition: all 0.2s ease;

          @media screen and (max-width: calc($tablet - 1px)) {
            width: 42px;
          }
        }

        .content {
          width: calc(100% - 72px);
          @media screen and (max-width: calc($tablet - 1px)) {
            width: calc(100% - 56px);
          }
          .item-title {
            margin-bottom: 8px;
            transition: all 0.2s ease;
          }
          .description {
            letter-spacing: 0.5px;
            opacity: 0.8;
            margin-bottom: 0;
            transition: all 0.2s ease;
          }
        }
      }
    }

    .col-2 {
      width: calc(100% - 520px);
      display: flex;
      justify-content: center;

      @media screen and (max-width: calc($desktop-medium - 1px)) {
        width: 100%;
        :deep(.frame) {
          width: 600px !important;
          height: 400px !important;
        }
      }

      @media screen and (max-width: calc($tablet - 1px)) {
        :deep(.frame) {
          width: 100% !important;
          height: unset !important;
          aspect-ratio: 3 / 2;
        }
      }
    }
  }
}
</style>
