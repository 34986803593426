<template>
  <footer>
    <div class="main-footer">
      <div class="container">
        <div class="col-1">
          <img src="@/assets/images/cydas.png" alt="" />
          <h5 class="text-h5 title">{{ title1 }}</h5>
          <p class="text-body-regular-extra-large description">
            {{ description }}
          </p>
        </div>
        <div class="col-2">
          <img src="@/assets/images/morsoftware.svg" alt="" />
          <p class="text-body-regular-extra-large description">{{ title2 }}</p>
          <p class="row-info text-body-regular-medium">
            <img src="@/assets/icons/mail-icon.svg" alt="" /> {{ contact }}
          </p>
          <p class="row-info text-body-regular-medium">
            <img src="@/assets/icons/phone-icon.svg" alt="" /> {{ phone }}
          </p>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <span class="text-body-regular-extra-small">{{ bottom }}</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      title1: "Kiến tạo Doanh nghiệp số",
      description:
        "Trải nghiệm nền tảng quản trị nhân sự hàng đầu Nhật Bản ngay hôm nay. +1000 doanh nghiệp hàng đầu Nhật Bản, Hàn Quốc đã tin dùng",
      title2: "Độc quyền phân phối tại Việt Nam",
      contact: "contact@morsoftware.com",
      phone: "(+84) 869 738 833",
      bottom: "© 2023 All Rights Reserved",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
footer {
  background: #013a5b;
  h5,
  p {
    color: white;
    margin: 0;
  }
  .main-footer {
    .container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 32px;
      padding-top: 35px;
      padding-bottom: 28px;
      @media screen and (max-width: calc($tablet - 1px)) {
        padding-top: 32px;
        padding-bottom: 24px;
      }
      .col-1 {
        width: 361px;
        flex-shrink: 0;
        @media screen and (max-width: calc($tablet - 1px)) {
          width: 100%;
        }
        img {
          filter: brightness(0) invert(1);
          height: 42px;
          width: auto;
          margin-bottom: 41px;

          @media screen and (max-width: calc($tablet - 1px)) {
            margin-bottom: 24px;
            width: 100%;
            max-width: 180px;
            height: auto;
          }
        }
        .title {
          margin-bottom: 14px;
        }
        p {
          padding-right: 39px;
        }
      }
      .col-2 {
        width: 274px;
        flex-shrink: 0;

        img {
          height: 45px;
          width: auto;
          margin-bottom: 15px;
        }

        p {
          margin-bottom: 14px;
          display: flex;
          align-items: center;
          gap: 14px;

          &:nth-child(2) {
            font-weight: 700;
          }

          &:last-child {
            margin: 0;
          }

          img {
            width: 24px;
            height: 24px;
            display: block;
            margin: 0;
          }
        }
      }
    }
  }
  .footer-bottom {
    background: #22242a;
    padding: 19px 0 27px;

    @media screen and (max-width: calc($desktop-medium - 1px)) {
      padding: 16px 0;
    }
    span {
      padding-left: 32px;
      color: white;
      @media screen and (max-width: calc($desktop-medium - 1px)) {
        padding-left: 0;
      }
    }
  }
}
</style>
