<template>
  <div class="frame" :style="{ width }">
    <div class="layer back" :style="styleBack">
      <slot name="back"></slot>
    </div>
    <div class="layer image" :style="styleFront">
      <slot name="front"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    styleFront: {
      type: Object,
      default: () => ({}),
    },
    styleBack: {
      type: Object,
      default: () => ({}),
    },
    width: {
      type: [Number, String],
      default: () => "100%",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.frame {
  position: relative;
  height: 418px;

  .layer {
    position: absolute;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    border-radius: 32px;
    overflow: hidden;

    @media screen and (max-width: calc($desktop-medium - 1px)) {
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      border-radius: 32px;
    }

    &.image {
      top: 0;
      left: 0;
    }

    &.back {
      top: 16px;
      right: 0;

      @media screen and (max-width: calc($desktop-medium - 1px)) {
        top: 12px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
