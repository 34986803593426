<template>
  <div class="layout">
    <div class="container">
      <div class="text-h1 title">{{ title }}</div>
      <div class="body">
        <div class="item" v-for="(data, idx) in listData" :key="idx">
          <div class="col-1">
            <img :src="data.icon" alt="" />
          </div>
          <div class="col-2">
            <h5 class="text-h6 item-title">{{ data.title }}</h5>
            <p class="description text-body-extra-large">
              {{ data.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Các đặc tính ưu việt",
      listData: [
        {
          title: "Quản trị mục tiêu",
          description:
            "Nhân viên tự thiết lập mục tiêu và cùng cấp quản lý đánh giá hiệu quả công việc, giúp quy trình quản lý nhân sự trở nên minh bạch hơn.",
          icon: require("@/assets/icons/target-icon.svg"),
        },
        {
          title: "Số hoá quy trình vận hành",
          description:
            "Tất cả các thông tin về quy trình quản lý nhân sự được tích hợp vào ứng dụng và đồng bộ trên mọi thiết bị.",
          icon: require("@/assets/icons/operation-icon.svg"),
        },
        {
          title: "Hệ thống hóa dữ liệu ",
          description:
            "Các thông tin nhân viên và doanh nghiệp được trình bày một cách hệ thống và trực quan, giúp đánh giá và dự báo chính xác hơn.",
          icon: require("@/assets/icons/chart-icon.svg"),
        },
        {
          title: "Tương thích với Excel",
          description:
            "Doanh nghiệp dễ dàng chuyển đổi số với chức năng xuất - nhập dữ liệu giữa Excel và Cydas People mà không cần cài thêm ứng dụng khác. ",
          icon: require("@/assets/icons/excel-icon.svg"),
        },
        {
          title: "Hệ thống thông tin và giao tiếp ",
          description:
            "Các thông báo về văn bản và truyền thông nội bộ được cập nhật tức thời, giúp các nhân viên kết nối và trao đổi cởi mở hơn",
          icon: require("@/assets/icons/document-setting-icon.svg"),
        },
        {
          title: "Cập nhật tức thời",
          description:
            "Tiến độ và nội dung công việc được cập nhật tức thời và xuyên suốt giữa các phòng ban.",
          icon: require("@/assets/icons/operation-check-icon.svg"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.layout {
  padding: 80px 0;
  @media screen and (max-width: calc($desktop-medium - 1px)) {
    padding: 40px 0;
  }
  @media screen and (max-width: calc($tablet - 1px)) {
    padding: 32px 0;
  }
  .title {
    text-align: center;
    color: $primary;
    margin-bottom: 40px;

    @media screen and (max-width: calc($tablet - 1px)) {
      margin-bottom: 24px;
    }
  }
  .body {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    .item {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      width: calc(33.333333333333333% - 26.6666666667px);
      @media screen and (max-width: calc($desktop-medium - 1px)) {
        width: calc(50% - 20px);
        gap: 16px;
      }
      @media screen and (max-width: calc($tablet - 1px)) {
        width: 100%;
      }
      .col-1 {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background: $primary;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        @media screen and (max-width: calc($desktop-medium - 1px)) {
          width: 48px;
          height: 48px;
        }
        img {
          width: 36px;
          aspect-ratio: 1 / 1;
          @media screen and (max-width: calc($desktop-medium - 1px)) {
            width: 28px;
          }
        }
      }

      .col-2 {
        width: calc(100% - 88px);
        @media screen and (max-width: calc($desktop-medium - 1px)) {
          width: calc(100% - 64px);
        }
        .item-title {
          color: $primary;
          margin-bottom: 8px;
        }
        .description {
          letter-spacing: 0.5px;
          opacity: 0.9;
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
