import { render, staticRenderFns } from "./SocialProof.vue?vue&type=template&id=cc78e734&scoped=true&"
import script from "./SocialProof.vue?vue&type=script&lang=js&"
export * from "./SocialProof.vue?vue&type=script&lang=js&"
import style0 from "./SocialProof.vue?vue&type=style&index=0&id=cc78e734&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc78e734",
  null
  
)

export default component.exports