<template>
  <div class="button" @click="goTopTop" v-if="show">
    <img src="@/assets/icons/arrow-left-icon.svg" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll);
  },
  methods: {
    goTopTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    scroll() {
      this.show = window.scrollY > 150;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.button {
  position: fixed;
  bottom: 32px;
  right: 32px;
  height: 48px;
  width: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;

  box-shadow: 0 0 4px #56c4ff;

  opacity: 0.8;

  @media screen and (max-width: calc($desktop-medium - 1px)) {
    bottom: 24px;
    right: 24px;
  }
  @media screen and (max-width: calc($tablet - 1px)) {
    bottom: 16px;
    right: 16px;
    height: 42px;
    width: 42px;
  }
  &:hover {
    opacity: 1;
  }

  img {
    transform: rotate(90deg);
  }
}
</style>
