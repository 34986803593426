<template>
  <div class="layout">
    <div class="top">
      <div class="container">
        <div class="text-h1 title">{{ title }}</div>
        <a
          :href="button1.link"
          target="_blank"
          rel="noopener noreferrer"
          class="font-button button button-primary"
        >
          {{ button1.text }}
        </a>
      </div>
    </div>
    <div class="body">
      <div class="item" v-for="(data, idx) in listData" :key="idx">
        <div :class="['container', idx % 2 !== 0 && 'reverse']">
          <div class="col-text">
            <h2 class="text-h2 item-title">{{ data.title }}</h2>
            <p class="description text-body-extra-large">
              {{ data.description }}
            </p>
          </div>
          <ImageFrame
            class="col-image"
            :styleFront="
              idx % 2 === 0 ? { zIndex: -1, background: '#007BC3' } : {}
            "
            :styleBack="idx % 2 === 0 ? {} : { background: '#007BC3' }"
          >
            <template v-slot:back>
              <img :src="data.image" v-if="idx % 2 === 0" />
            </template>
            <template v-slot:front>
              <img :src="data.image" v-if="idx % 2 !== 0" />
            </template>
          </ImageFrame>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="container">
        <div class="text-h1 title">{{ title2 }}</div>
        <a
          :href="button2.link"
          target="_blank"
          rel="noopener noreferrer"
          class="font-button button button-primary"
        >
          {{ button2.text }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ImageFrame from "./common/ImageFrame.vue";
import { LINK } from "@/constants";
export default {
  components: { ImageFrame },
  data() {
    return {
      title: "Phát triển doanh nghiệp toàn diện với CYDAS People",
      title2: "Trải nghiệm HR 4.0 với CYDAS People ngay!",
      button1: {
        text: "Nhận demo MIỄN PHÍ",
        link: LINK.REGISTER,
      },
      button2: {
        text: "Nhận demo MIỄN PHÍ",
        link: LINK.REGISTER,
      },
      listData: [
        {
          title: "Số hóa quy trình làm việc giúp nâng cao hiệu suất công việc",
          description:
            "Áp dụng công nghệ hiện đại của CYDAS People trong các hoạt động của Doanh Nghiệp, bao gồm quản lý công việc, thông tin, nhân sự và tri thức, giúp xây dựng luồng dữ liệu liên tục trong toàn bộ tổ chức. Kết quả là nhân viên làm việc hiệu quả, và nhà quản lý có thể quản trị và ra quyết định một cách dễ dàng. Đồng thời, tổ chức bộ máy trở nên tinh gọn và các hoạt động nội bộ được số hóa hoàn toàn.",
          image: require("@/assets/images/call-to-action-1.png"),
        },
        {
          title: "Xây dựng trải nghiệm nhân viên hạnh phúc",
          description:
            "Ứng dụng những lý thuyết quản trị hiện đại, CYDAS People giúp Doanh nghiệp xây dựng các giải pháp nhân sự có tính thực tiễn cao. Điều này đồng nghĩa với việc Doanh nghiệp sẽ thực hiện quản trị bài bản, công bằng và minh bạch. Nhờ vào việc áp dụng các nguyên tắc này, nhân viên được khuyến khích và hỗ trợ phát triển, làm việc hiệu quả và có trách nhiệm trong công việc của mình.",
          image: require("@/assets/images/call-to-action-2.png"),
        },
        {
          title: "Kiến tạo một doanh nghiệp số hoàn chỉnh",
          description:
            "CYDAS People là giải pháp số toàn diện bao gồm Công việc số, Quy trình số, Thông tin số, Tri thức số và Con người số, giúp Doanh Nghiệp tăng tốc thực thi và phát triển con người toàn diện. Bằng cách thay đổi phong cách làm việc, Doanh Nghiệp có thể chạm đến những đỉnh cao mới. CYDAS People tối ưu hóa hiệu suất làm việc, chia sẻ thông tin, nắm bắt tri thức và tăng cường tương tác giữa các thành viên. Điều này tạo nên môi trường động lực và sáng tạo, giúp Doanh Nghiệp thành công bền vững.",
          image: require("@/assets/images/call-to-action-3.png"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.top,
.bottom {
  padding: 80px 0;
  @media screen and (max-width: calc($desktop-medium - 1px)) {
    padding: 40px 0;
  }
  @media screen and (max-width: calc($tablet - 1px)) {
    padding: 32px 0;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;

    @media screen and (max-width: calc($desktop-medium - 1px)) {
      gap: 24px;
    }

    .title {
      text-align: center;
      color: $primary;
      width: 100%;
      padding: 0 60px;

      @media screen and (max-width: calc($desktop-medium - 1px)) {
        padding: 0;
      }
    }
  }
}
.body {
  .item {
    padding: 80px 0;
    @media screen and (max-width: calc($desktop-medium - 1px)) {
      padding: 40px 0 0;
    }
    @media screen and (max-width: calc($tablet - 1px)) {
      padding: 32px 0 0;
    }
    .container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 40px;

      @media screen and (max-width: calc($desktop-medium - 1px)) {
        justify-content: center;
        gap: 32px;
      }

      @media screen and (max-width: calc($desktop-medium - 1px)) {
        gap: 16px;
      }

      &.reverse {
        flex-direction: row-reverse;
      }

      .col-text,
      .col-image {
        width: calc(50% - 20px);
        flex-shrink: 0;
      }

      .col-image {
        max-width: 620px;
        @media screen and (max-width: calc($desktop-medium - 1px)) {
          width: 600px !important;
          height: unset;
          aspect-ratio: 3 / 2;
        }
        @media screen and (max-width: calc($tablet - 1px)) {
          width: 100% !important;
          max-width: 500px;
        }
      }

      .col-text {
        @media screen and (max-width: calc($desktop-medium - 1px)) {
          width: 100%;
        }
        .item-title {
          color: $primary;
          margin-bottom: 16px;
        }
        .description {
          padding-right: 42.5px;
          opacity: 0.9;
          margin-bottom: 0;
          @media screen and (max-width: calc($desktop-medium - 1px)) {
            padding-right: 0;
          }
        }
      }
    }
  }
}
</style>
