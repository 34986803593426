<template>
  <header class="main-header">
    <div class="container">
      <img src="@/assets/images/cydas.png" alt="" />
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.main-header {
  margin-bottom: 24px;
  @media screen and (max-width: calc($desktop-medium - 1px)) {
    margin-bottom: 16px;
  }

  .container {
    padding-top: 50px;
    @media screen and (max-width: calc($desktop-medium - 1px)) {
      padding-top: 32px;
    }
    @media screen and (max-width: calc($tablet - 1px)) {
      padding-top: 24px;
    }
    img {
      height: 56px;
      width: auto;

      @media screen and (max-width: calc($desktop-medium - 1px)) {
        height: 50px;
      }

      @media screen and (max-width: calc($tablet - 1px)) {
        height: 38px;
      }
    }
  }
}
</style>
