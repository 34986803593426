<template>
  <div class="layout">
    <div class="container">
      <h1 class="text-h1 title">{{ title }}</h1>
      <carousel
        class="body"
        :margin="40"
        :autoplayTimeout="10000"
        :autoplaySpeed="2500"
        :items="3"
        :dots="false"
        :autoplay="true"
        :loop="true"
        :autoWidth="true"
        :autoplayHoverPause="true"
      >
        <div class="item" v-for="(data, idx) in listData" :key="idx">
          <div class="avatar">
            <img :src="data.image" alt="" />
          </div>
          <div class="info">
            <p class="text-body-extra-large description">
              "{{ data.description }}"
            </p>
            <h5 class="text-h6 author">{{ data.author }}</h5>
            <p class="text-body-large">{{ data.company }}</p>
            <p class="text-body-large">{{ data.position }}</p>
          </div>
        </div>
      </carousel>
      <div class="footer">
        <div class="button" @click="onClickPrev">
          <img src="@/assets/icons/arrow-left-icon.svg" alt="" />
        </div>
        <div class="button" @click="onClickNext">
          <img src="@/assets/icons/arrow-right-icon.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "owl-carousel-vuejs";

export default {
  components: { carousel },
  data() {
    return {
      title: "Đánh giá của khách hàng",
      listData: [
        {
          description:
            "Chúng tôi đã quyết định chọn CYDAS People vì sự tiện lợi của tính năng HR, thực tế rằng đây là một hệ thống có thể được sử dụng bởi tất cả nhân viên mà không cần giải thích, hiệu suất chi phí và sự mượt mà của việc chia sẻ thông tin.",
          author: "Bà Akie Sakai",
          company: "Công ty eGuarantee",
          position: "Quản lý phòng Nhân sự",
          image: require("@/assets/images/proof-1.png"),
        },
        {
          description:
            "Chúng tôi đề cao việc triển khai chức năng Quản lý mục tiêu để đánh giá nhân viên, từ đó hiểu được nhiều thông tin về nhân viên. Nhờ đó chúng tôi có thể đánh giá tổng thể doanh nghiệp cũng như phân tích hiệu suất của từng cá nhân. Đây là điều chưa từng có với chúng tôi. Nó thật sự thú vị.",
          author: "Ông Hiroyuki Yoshida",
          company: "Ngân hàng Kiraboshi",
          position: "Trưởng phòng Nhân sự",
          image: require("@/assets/images/proof-2.png"),
        },
        {
          description:
            "Chúng tôi đã ứng dụng Sổ ghi chú CYDAS như một công cụ để nắm bắt thông tin của mỗi kỹ thuật viên và hỗ trợ quá trình đào tạo của họ. Với ứng dụng này, hiện nay chúng tôi có thể chia sẻ không chỉ gương mặt, danh tính và kỹ năng, mà còn cả thông tin như quá trình đào tạo và tiến trình.",
          author: "Ông Keitaro Hibi",
          company: "Công ty All Nippon Airways",
          position: "Phòng Quan hệ khách hàng",
          image: require("@/assets/images/proof-3.png"),
        },
      ],
    };
  },
  methods: {
    onClickNext() {
      const nextButton = document.querySelector(".owl-next");
      nextButton.click();
    },
    onClickPrev() {
      const prevButton = document.querySelector(".owl-prev");
      prevButton.click();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.layout {
  padding: 40px 0;
  background: $primary;
  overflow: hidden;

  @media screen and (max-width: calc($tablet - 1px)) {
    padding: 32px 0;
  }

  .container {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      background: $primary;
      width: 100%;
      height: 100%;
      top: 0;
      left: calc(-100% + 80px);
      z-index: 2;

      @media screen and (max-width: calc($desktop-medium - 1px)) {
        left: calc(-100% + 12px);
      }

      @media screen and (max-width: calc($tablet - 1px)) {
        display: none;
      }
    }
  }

  .title {
    color: white;
    margin-bottom: 40px;

    @media screen and (max-width: calc($desktop-medium - 1px)) {
      margin-bottom: 32px;
    }

    @media screen and (max-width: calc($tablet - 1px)) {
      margin-bottom: 16px;
    }
  }

  .body {
    :deep(.owl-stage-outer) {
      overflow: visible;
    }
    :deep(.owl-stage) {
      display: flex;
      align-items: stretch;
    }
    :deep(.owl-nav) {
      display: none;
    }
    .item {
      width: 549px;
      height: 100%;
      flex-shrink: 0;
      border-radius: 16px;
      padding: 24px;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 24px;
      border: 2px solid #ffffff;
      transition: all 0.2s ease;
      @media screen and (max-width: calc($desktop-medium - 1px)) {
        width: 450px;
        padding: 16px;
        gap: 16px;
      }
      @media screen and (max-width: calc($tablet - 1px)) {
        width: 298px;
        padding: 12px;
        flex-direction: column;
        align-items: center;
      }
      &:hover {
        background: white;
        p,
        h5 {
          color: $primary !important;
        }
      }
      .avatar {
        width: 80px;
        height: 80px;
        border-radius: 8px;
        flex-shrink: 0;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @media screen and (max-width: calc($desktop-medium - 1px)) {
          width: 64px;
          height: 64px;
        }
        @media screen and (max-width: calc($tablet - 1px)) {
          width: 80px;
          height: 80px;
        }
      }
      .info {
        width: calc(100% - 104px);

        @media screen and (max-width: calc($desktop-medium - 1px)) {
          width: calc(100% - 80px);
        }
        @media screen and (max-width: calc($tablet - 1px)) {
          width: 100%;
        }

        p,
        h5 {
          margin-bottom: 0;
          color: white;
          transition: all 0.2s ease;
        }

        .description {
          letter-spacing: 0.5px;
          margin-bottom: 16px;
        }

        .author {
          font-weight: 700;
        }
      }
    }
  }

  .footer {
    margin-top: 40px;
    display: flex;
    gap: 24px;

    @media screen and (max-width: calc($desktop-medium - 1px)) {
      margin-top: 32px;
      gap: 16px;
    }

    @media screen and (max-width: calc($tablet - 1px)) {
      margin-top: 16px;
    }

    .button {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s ease;
      &:hover {
        background: $grey;
      }
      img {
        display: block;
        height: 40px;
        width: 40px;
      }
    }
  }
}
</style>
