<template>
  <div class="layout">
    <div class="container">
      <div class="col-1">
        <h1 class="text-h1 title">{{ title }}</h1>
        <p class="text-body-extra-large description">{{ description }}</p>
        <div class="list-btn">
          <a
            :href="button1.link"
            target="_blank"
            rel="noopener noreferrer"
            class="text-h6 button button-primary"
          >
            {{ button1.text }}
          </a>
          <a
            :href="button2.link"
            target="_blank"
            rel="noopener noreferrer"
            class="text-h6 button"
          >
            {{ button2.text }}
          </a>
        </div>
      </div>
      <div class="col-2">
        <img src="@/assets/images/hero-banner.png" alt="" />
        <img src="@/assets/images/circle.svg" alt="" class="circle" />
        <div class="list-btn">
          <a
            :href="button1.link"
            target="_blank"
            rel="noopener noreferrer"
            class="text-h6 button button-primary"
          >
            {{ button1.text }}
          </a>
          <a
            :href="button2.link"
            target="_blank"
            rel="noopener noreferrer"
            class="text-h6 button"
          >
            {{ button2.text }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LINK } from "@/constants";
export default {
  data() {
    return {
      title: "Giải pháp\nquản trị nhân sự 4.0\nsố 1 Nhật Bản",
      description:
        "CYDAS PEOPLE là hệ thống quản lý nhân sự số toàn diện được thiết kế bởi CYDAS Inc. và phân phối độc quyền tại Việt Nam bởi MOR Software. Là 1 ứng dụng All in 1, CYDAS PEOPLE tích hợp tất cả dữ liệu nhân sự và hệ thống hóa bộ máy tổ chức giúp cho quy trình quản lý doanh nghiệp trở nên tinh gọn hơn chỉ trong 1 nền tảng.",
      button1: {
        text: "Đăng ký ngay",
        link: LINK.REGISTER,
      },
      button2: {
        text: "Liên hệ tư vấn",
        link: LINK.CONTACT,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.layout {
  .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    padding-top: 45.5px;
    padding-bottom: 80px;
    @media screen and (max-width: calc($desktop-medium - 1px)) {
      justify-content: center;
      padding-bottom: 40px;
      padding-top: 40px;
      gap: 0;
    }
    @media screen and (max-width: calc($tablet - 1px)) {
      padding-bottom: 32px;
      padding-top: 12px;
    }

    .col-1,
    .col-2 {
      width: calc(50% - 20px);
      flex-shrink: 0;
    }
    .col-1 {
      padding-top: 44px;
      @media screen and (max-width: calc($desktop-medium - 1px)) {
        width: 100%;
        padding-top: 0;
      }
      .title {
        color: $primary;
        margin-bottom: 28px;
        white-space: pre-wrap;
        @media screen and (max-width: calc($desktop-medium - 1px)) {
          white-space: unset;
          margin-bottom: 18px;
        }
        @media screen and (max-width: calc($tablet - 1px)) {
          margin-bottom: 16px;
        }
      }
      .description {
        padding-right: 43px;
        letter-spacing: 0.5px;
        opacity: 0.9;
        margin-bottom: 34px;
        @media screen and (max-width: calc($desktop-medium - 1px)) {
          padding-right: 0;
          margin-bottom: 24px;
        }
        @media screen and (max-width: calc($tablet - 1px)) {
          margin-bottom: 16px;
        }
      }
      .list-btn {
        display: flex;
        gap: 32px;

        @media screen and (max-width: calc($desktop-medium - 1px)) {
          display: none;
        }
      }
    }
    .col-2 {
      position: relative;
      .list-btn {
        display: none;
        margin-top: 32px;
        gap: 32px;

        @media screen and (max-width: calc($desktop-medium - 1px)) {
          display: flex;
          justify-content: center;
        }
        @media screen and (max-width: calc($tablet - 1px)) {
          gap: 12px;
        }
      }
      @media screen and (max-width: calc($desktop-medium - 1px)) {
        width: 400px;
      }
      @media screen and (max-width: calc($tablet - 1px)) {
        width: 298px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.circle {
          width: 313.34px;
          height: 313.34px;
          position: absolute;
          top: 61px;
          left: 37px;
          z-index: -1;
          animation-name: rotate;
          animation-duration: 30s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;

          @media screen and (max-width: calc($desktop-medium - 1px)) {
            width: 200px;
            height: 200px;
            top: 38px;
            left: 30px;
          }

          @media screen and (max-width: calc($tablet - 1px)) {
            width: 150px;
            height: 150px;
            top: 27px;
            left: 25px;
          }
        }
      }
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>
